import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Banner from '~/components/Banner/Banner';
import useAuthQuery from '~/hooks/useAuthQuery';
import { cssMerge } from '~/services/utils';
import { toast } from '#/shared/utilities';
import BASIC_TOKEN from '#/Components/Containers/Settings/Api/basicToken.gql';

import css from './ApiTokenBanner.module.scss';

type Props = {
  onDismiss: () => void;
}

const ApiTokenBanner: React.FC<Props> = ({ onDismiss }) => {
  const { t } = useTranslation(['common']);
  const [show, setShow] = useState(false);
  const [blink, setBlink] = useState(false);
  const { data, loading } = useAuthQuery(BASIC_TOKEN);

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);

      setShow(true);
      setBlink(true);
      setTimeout(() => setBlink(false), 500);
    } catch (e) {
      toast.error(
        <>
          <Trans
            i18nKey="common:Banner.SetupModule.copyFailedMessage"
            values={{
              link: '/settings/api',
            }}
            // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
            components={{ a: <a /> }}
          />
        </>,
      );
    }
  };

  const actions = (
    <>
      {data?.basicToken ? (
        <div className="position-relative">
          <Button color="primary" onClick={() => copyToClipboard(data?.basicToken ?? '')}>
            {t('common:Banner.SetupModule.copyButton')}
          </Button>
          <span
            className={cssMerge(
              css.copyNote,
              'mt-1 text-center',
              show ? 'visible' : 'invisible',
              blink && css.blink,
            )}
          >
            {t('common:Banner.SetupModule.copyNote')}
          </span>
        </div>
      ) : (
        <Link to="/settings/api" className="btn btn-secondary mr-3">
          {t('common:Banner.SetupModule.apiSettingsButton')}
        </Link>
      )}
    </>
  );

  return (
    <Banner
      title={t('common:Banner.SetupModule.title')}
      imgSrc="/assets/img/banners/puzzle.png"
      imgAlt={t('common:Banner.SetupModule.imageDescription')}
      actions={loading ? null : actions}
      canDismiss
      onDismiss={onDismiss}
    >
      <p className="mb-2">
        {t('common:Banner.SetupModule.description')}
      </p>

      <a
        href="https://intercom.help/foxdeli/cs/articles/5219979-shoptet-doplnek-foxdeli"
        target="_blank"
        rel="noreferrer"
      >
        {t('common:Banner.SetupModule.howToLink')}
      </a>
    </Banner>
  );
};

export default ApiTokenBanner;
