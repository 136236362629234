import { IOptions } from '@airbrake/browser';

interface Config {
  uiVersion: string;
  adminUrl: string;
  cookieDomain?: string;
  graphqlUrl: string;
  restApiUrl: string;
  apolloGqlUrl?: string;
  searchDebounce: number;
  sessionTimeout: number;
  ttUrl: string;
  iamUrl: string;
  firebase: {
    appId: string;
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
  };
  airbrake: Nullable<IOptions>;
  i18nVersion: string;
  publicAssetsUrl: string;
  carrierAssetsUrl: string;
  googleAnalyticsId?: string;
  intercomAppId?: string;
  hotjarId?: string;
}

type Environment = 'development' | 'production';

class Configurator {
  private cfg: Config;

  private environment: Environment;

  constructor() {
    // TODO: we should not fallback to hardcoded development but probably fail the whole app
    this.environment = (process.env.NODE_ENV as Environment) || 'development';

    this.cfg = {
      uiVersion: process.env.PACKAGE_VERSION as string,
      adminUrl: process.env.ADMIN_URL as string,
      cookieDomain: Configurator.checkVoid(process.env.COOKIE_DOMAIN),
      graphqlUrl: process.env.GQL_URL as string,
      restApiUrl: process.env.REST_API_URL as string,
      apolloGqlUrl: Configurator.checkVoid(process.env.APOLLO_GQL_URL),
      searchDebounce: parseInt(process.env.SEARCH_DEBOUNCE as string, 10),
      sessionTimeout: parseInt(process.env.SESSION_TIMEOUT as string, 10),
      ttUrl: process.env.TT_URL as string,
      iamUrl: process.env.IAM_URL as string,

      firebase: {
        appId: process.env.FIREBASE_APP_ID as string,
        apiKey: process.env.FIREBASE_API_KEY as string,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN as string,
        databaseURL: process.env.FIREBASE_DB_URL as string,
        projectId: process.env.FIREBASE_PROJECT_ID as string,
        storageBucket: '',
        messagingSenderId: process.env.FIREBASE_SENDER_ID as string,
      },

      airbrake: Configurator.buildAirbrakeConfig(),
      i18nVersion: process.env.I18N_VERSION as string,
      publicAssetsUrl: process.env.PUBLIC_ASSETS_URL as string,
      carrierAssetsUrl: 'https://static.stiteknabalik.cz',

      googleAnalyticsId: Configurator.checkVoid(process.env.GOOGLE_ANALYTICS_ID),
      intercomAppId: Configurator.checkVoid(process.env.INTERCOM_APP_ID),
      hotjarId: Configurator.checkVoid(process.env.HOTJAR_ID),
    };
  }

  private static buildAirbrakeConfig(): Nullable<IOptions> {
    const projectId = process.env.AIRBRAKE_PROJECT_ID && Configurator.checkVoid<number>(
      parseInt(process.env.AIRBRAKE_PROJECT_ID, 10),
    );

    const projectKey = Configurator.checkVoid(process.env.AIRBRAKE_PROJECT_KEY);

    if (!projectId || !projectKey) {
      return null;
    }

    return {
      projectId,
      projectKey,
      host: Configurator.checkVoid(process.env.AIRBRAKE_HOST as string),
      environment: process.env.AIRBRAKE_ENVIRONMENT as string,
    };
  }

  /**
   * @description Empty env var definitions need to have NULL set explicitly in Amplify:
   * see https://github.com/aws-amplify/amplify-console/issues/1202*
   * Regarding `<T extends unknown>` in the function signature,
   * this is a weird TS generics behaviour: https://github.com/microsoft/TypeScript/issues/41402
   */
  private static checkVoid<T extends unknown>(value?: T): T | undefined {
    if (typeof value === 'undefined' || value === 'NULL') {
      return undefined;
    }

    return value;
  }

  get config() {
    return this.cfg;
  }

  get env() {
    return this.environment;
  }
}

export default new Configurator();
