import configurator from '../../../src/2020/services/Configurator';

export default class Intercom {
  static init() {
    if (!configurator.config.intercomAppId) return;

    /* eslint-disable */
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/q2qholk5';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    /* eslint-enable */
  }

  static setUser(
    name, surname, email, phone, companyName, companyWebsite, companyId, companyLicence,
  ) {
    if (!window.Intercom) return;

    const fullName = (name || surname) ? `${name} ${surname}` : undefined;

    window.Intercom('boot', {
      app_id: configurator.config.intercomAppId,
      custom_launcher_selector: '.intercom-toggle',
      name: fullName,
      email,
      phone,
      companyName,
      companyWebsite,
      companyId,
      companyLicence,
    });
  }
}
