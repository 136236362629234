import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import Loader from 'Containers/Loader/Loader';
import { toast } from 'Shared/utilities';
import { phoneNumber, zipCode } from 'Shared/formValidationRules/formValidationRules';
import Subject from 'Models/subject/SubjectDataSource';
import ContactForm from '../Components/Presentational/ContactForm/ContactForm';

Yup.addMethod(Yup.string, 'phoneNumber', phoneNumber);
Yup.addMethod(Yup.string, 'zipCode', zipCode);

class ContactsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.subject = new Subject();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      subjectId: null,
      subject: null,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    this.id = match.params.id;

    await this.getAndStoreSubject();
  }

  async getAndStoreSubject() {
    let subject;

    try {
      subject = await this.subject.selectById(this.id);
    } catch (e) {
      console.warn(e);
      return;
    }

    subject = {
      ...subject.data.subjectById.contact,
      ...subject.data.subjectById.address,
    };

    this.setState({
      subjectId: this.id,
      subject,
    });
  }

  handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);

    const { subjectId } = this.state;
    const { t, history } = this.props;

    const contact = {
      name: values.name,
      surname: values.surname,
      companyName: values.companyName,
      email: values.email,
      phone: values.phone,
    };

    const address = {
      country: values.country,
      street: values.street,
      zip: values.zip,
      city: values.city,
    };

    if (typeof (address.country) === 'object') {
      address.country = address.country.value;
    }

    this.subject.updateSubject(subjectId, contact, address)
      .then((res) => {
        history.push(`/contacts/${res.data.updateSubject.id}`);
        this.setState({ subjectId: res.data.updateSubject.id });
        toast.success(t('contacts:Banner.contactEditSuccess'));
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  render() {
    const { t } = this.props;
    const { subject } = this.state;
    let content = <Loader />;

    if (subject !== null) {
      const {
        subject: {
          name: subjectName,
          surname: subjectSurname,
          email: subjectEmail,
          companyName: subjectCompanyName,
          phone: subjectPhone,
          country: subjectCountry,
          street: subjectStreet,
          zip: subjectZip,
          city: subjectCity,
        },
      } = this.state;

      content = (
        <MainBody
          wrapperHeight="150px"
          title={`${subjectName} ${subjectSurname}`}
          col={{ xs: 12, sm: 12, md: 8, lg: 8 }}
        >
          <Container>
            <Formik
              initialValues={{
                name: subjectName,
                surname: subjectSurname,
                companyName: subjectCompanyName ?? '',
                email: subjectEmail,
                phone: subjectPhone,
                country: subjectCountry,
                street: subjectStreet,
                zip: subjectZip,
                city: subjectCity,
              }}
              onSubmit={this.handleSubmit}
              validationSchema={Yup.object().shape({
                surname: Yup.string()
                  .required(t('validation:SurnameOrFirm.required')),
                email: Yup.string()
                  .required(t('validation:Email.required'))
                  .email(t('validation:Email.pattern')),
                phone: Yup.string()
                  .required(t('validation:Phone.required'))
                  .phoneNumber(t),
                street: Yup.string()
                  .required(t('validation:Street.required')),
                zip: Yup.string()
                  .required(t('validation:ZipCode.required'))
                  .zipCode(t),
                city: Yup.string()
                  .required(t('validation:City.required')),
              })}
              component={(props) => (
                <ContactForm
                  {...props}
                  buttonLabel={t('contacts:EditContact.submitButton')}
                  formAction="edit"
                />
              )}
            />
          </Container>
        </MainBody>
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

ContactsEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
};

ContactsEdit.defaultProps = { match: { params: { id: null } } };

export default withTranslation('contacts')(ContactsEdit);
