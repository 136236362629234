import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getItem, setItem } from '~/services/localStorage';
import Icon from '~/components/Icon/Icon';

import css from './WelcomeModal.module.scss';

const WelcomeModal: React.FC = () => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(true);

  const getUserReaction = () => {
    const initialValue = getItem('hasClosedWelcomeModal');
    return initialValue === 'true';
  };

  const [modalClose, setModalClose] = useState<boolean>(getUserReaction());

  const setUserReaction = (value: boolean) => {
    setModalOpen(false);
    setModalClose(value);
    setItem('hasClosedWelcomeModal', JSON.stringify(value));
  };

  const handleContinue = () => {
    setUserReaction(true);
    history.push('/settings/collection-places');
  };

  if (modalClose) {
    return null;
  }

  return (
    <Modal
      isOpen={modalOpen}
      centered
      className={`${css.welcomeModal} text-center`}
    >
      <button
        type="button"
        onClick={() => setUserReaction(true)}
        className={css.closeButton}
      >
        <Icon name="close" />
      </button>

      <ModalBody>
        <img
          src="/assets/img/welcome.png"
          alt="Hands creating a heart with fingers"
          width={332}
          height={232}
          className="mt-2 mb-2"
        />

        <h2 className={`${css.title} mt-4`}>
          {t('common:WelcomeModal.title')}
        </h2>

        <p className="mt-3">
          {t('common:WelcomeModal.description')}
        </p>

        <Button
          color="primary"
          className="mt-2"
          onClick={handleContinue}
        >
          {t('common:WelcomeModal.continueToSetupButton')}
        </Button>

        <p className="mt-3">
          {t('common:WelcomeModal.supportInfo')}
          {' '}
          <a
            href="mailto:info@foxdeli.com"
            className="intercom-toggle"
          >
            {t('common:WelcomeModal.supportLink')}
          </a>
        </p>

        <p className={`${css.footnote} mt-4 mb-0`}>
          <Trans i18nKey="common:WelcomeModal.infoText" />
        </p>
      </ModalBody>
    </Modal>
  );
};

export default WelcomeModal;
