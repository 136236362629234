import * as Yup from 'yup';
import { TFunction } from 'i18next';
// import { zipCode } from '../../../../shared/formValidationRules/formValidationRules';
import { SenderPickupType, RecipientPickupType } from './types';

// cannot use global notType as it overrides schema's typeError(): https://github.com/jquense/yup/issues/394#issuecomment-596453493
// Yup.setLocale({ mixed: { notType: 'Neplatný formát.' } });

// TODO: consider glogalizing czBuildingNumber and czZipCode validators
declare module 'yup' {
  interface StringSchema<T> {
    czBuildingNumber(this: StringSchema<T>, countryInputName: string, msg: string): StringSchema<T>;
    czZipCode(this: StringSchema<T>, countryInputName: string, msg: string): StringSchema<T>;
  }

  interface NumberSchema<T> {
    noWhitespace(this: NumberSchema<T>, msg: string): NumberSchema<T>;
  }
}

function czBuildingNumber(
  this: Yup.StringSchema,
  countryInputName: string,
  msg: string,
): Yup.StringSchema {
  return this.when(countryInputName, {
    is: 'CZ',
    then: Yup.string().test('czBuildingNumber', msg, (val) => /^.+ .*\d.*$/.test(val)),
  });
}

function czZipCode(
  this: Yup.StringSchema,
  countryInputName: string,
  msg: string,
): Yup.StringSchema {
  return this.when(countryInputName, {
    is: 'CZ',
    then: Yup.string().test('czZipCode', msg, (val) => /^[1-7][0-9]{4}$/.test(val)),
  });
}

// Yup allows number inputs with spaces: https://github.com/jquense/yup/issues/694
function noWhitespace(this: Yup.NumberSchema): Yup.NumberSchema {
  return this.transform((value: number, originalValue: string) => (/\s/.test(originalValue) ? NaN : value));
}

Yup.addMethod(Yup.string, 'czBuildingNumber', czBuildingNumber);
Yup.addMethod(Yup.string, 'czZipCode', czZipCode);
Yup.addMethod(Yup.number, 'noWhitespace', noWhitespace);

function formSchema(
  senderPickupType: SenderPickupType,
  recipientPickupType: RecipientPickupType,
  requiresCollectionPlacePostCode = false,
  t: TFunction,
): Yup.ObjectSchema {
  const dictionary = {
    required: {
      agent: t('deliveries:NewDelivery.AgentSelect.required'),
      agentService: t('deliveries:NewDelivery.AgentService.required'),
      surname: t('validation:Surname.required'),
      name: t('validation:Name.required'),
      street: t('validation:Street.required'),
      city: t('validation:City.required'),
      zip: t('validation:ZipCode.required'),
      email: t('validation:Email.required'),
      phone: t('validation:Phone.required'),
      state: t('validation:CountrySelect.required'),
      pickupPlace: t('deliveries:NewDelivery.PickupPlace.required'),
      collectionPlace: t('deliveries:NewDelivery.CollectionPlace.required'),
      collectionPlacePostCode: t('deliveries:NewDelivery.CollectionPlace.PostCode.required'),
      packageValue: t('deliveries:NewDelivery.Delivery.DeliveryValue.required'),
      externalId: t('deliveries:NewDelivery.Delivery.ExternalId.required'),
      variableSymbol: t('deliveries:NewDelivery.Delivery.VariableSymbol.required'),
      weight: t('validation:PackageWeight.required'),
      length: t('validation:PackageLength.required'),
      width: t('validation:PackageWidth.required'),
      height: t('validation:PackageHeight.required'),
    },
    invalid: {
      street: t('validation:Street.pattern'),
      zip: t('validation:ZipCode.pattern'),
      email: t('validation:Email.pattern'),
      phone: t('validation:Phone.pattern'),
      packageValue: t('deliveries:NewDelivery.Delivery.DeliveryValue.pattern'),
      packageCod: t('deliveries:NewDelivery.Delivery.CashOnDelivery.pattern'),
      weight: t('validation:PackageWeight.pattern'),
      length: t('validation:PackageLength.pattern'),
      width: t('validation:PackageWidth.pattern'),
      height: t('validation:PackageHeight.pattern'),
      variableSymbol: t('deliveries:NewDelivery.Delivery.VariableSymbol.pattern'),
    },
  };

  const senderCollectionPlaceSchema = Yup.object().shape({
    senderCollectionPlace: Yup.string()
      .required(dictionary.required.collectionPlace),
  });

  const senderCollectionPlacePostCodeSchema = Yup.object().shape({
    senderCollectionPlacePostCode: Yup.string()
      .required(dictionary.required.collectionPlacePostCode)
      .czZipCode('recipientState', dictionary.invalid.zip),
  });

  const senderAddressSchema = Yup.object().shape({
    senderAddressSurname: Yup.string()
      .required(dictionary.required.surname),

    senderAddressStreet: Yup.string()
      .required(dictionary.required.street)
      .czBuildingNumber('senderState', dictionary.invalid.street),

    senderAddressCity: Yup.string()
      .required(dictionary.required.city),

    senderAddressZip: Yup.string()
      .required(dictionary.required.zip)
      .czZipCode('senderState', dictionary.invalid.zip),

    senderState: Yup.string()
      .required(dictionary.required.state),

    senderEmail: Yup.string()
      .email(dictionary.invalid.email)
      .required(dictionary.required.email),

    senderPhone: Yup.string()
      .required(dictionary.required.phone)
      .matches(/^\+?[0-9 ]+$/, dictionary.invalid.phone),
  });

  const recipientCollectionPlaceSchema = Yup.object().shape({
    recipientCollectionPlace: Yup.string()
      .required(dictionary.required.collectionPlace),
  });

  const recipientPickupPlaceSchema = Yup.object().shape({
    recipientPickupPlace: Yup.string()
      .required(dictionary.required.pickupPlace),

    recipientAddressSurname: Yup.string()
      .required(dictionary.required.surname),

    recipientEmail: Yup.string()
      .email(dictionary.invalid.email)
      .required(dictionary.required.email),

    recipientPhone: Yup.string()
      .required(dictionary.required.phone)
      .matches(/^\+?[0-9 ]+$/, dictionary.invalid.phone),
  });

  const recipientAddressSchema = Yup.object().shape({
    recipientAddressSurname: Yup.string()
      .required(dictionary.required.surname),

    recipientEmail: Yup.string()
      .nullable() // prevent validation type error in an edge case in edit delivery
      .required(dictionary.required.email)
      .email(dictionary.invalid.email),

    recipientPhone: Yup.string()
      .nullable() // same as recipientEmail
      .required(dictionary.required.phone)
      .matches(/^\+?[0-9 ]+$/, dictionary.invalid.phone),

    recipientAddressStreet: Yup.string()
      .required(dictionary.required.street)
      .czBuildingNumber('recipientState', dictionary.invalid.street),

    recipientAddressCity: Yup.string()
      .required(dictionary.required.city),

    recipientAddressZip: Yup.string()
      .required(dictionary.required.zip)
      .czZipCode('recipientState', dictionary.invalid.zip),

    recipientState: Yup.string()
      .required(dictionary.required.state),
  });

  const packageSchema = Yup.object().shape({
    weight: Yup.number()
      .noWhitespace(dictionary.invalid.weight)
      .nullable()
      .typeError(dictionary.invalid.weight),
    // .required(dictionary.required.weight),
    length: Yup.number()
      .noWhitespace(dictionary.invalid.length)
      .nullable()
      .integer(dictionary.invalid.length)
      .typeError(dictionary.invalid.length),
    // .required(dictionary.required.length),
    width: Yup.number()
      .noWhitespace(dictionary.invalid.width)
      .nullable()
      .integer(dictionary.invalid.width)
      .typeError(dictionary.invalid.width),
    // .required(dictionary.required.width),
    height: Yup.number()
      .noWhitespace(dictionary.invalid.height)
      .nullable()
      .integer(dictionary.invalid.height)
      .typeError(dictionary.invalid.height),
    // .required(dictionary.required.height),
  });

  const coreSchema = Yup.object().shape({
    agent: Yup.string()
      .required(dictionary.required.agent),

    agentService: Yup.string()
      .required(dictionary.required.agentService),

    packageValue: Yup.number()
      .noWhitespace(dictionary.invalid.packageValue)
      .required(dictionary.required.packageValue)
      .typeError(dictionary.invalid.packageValue)
      .min(0, dictionary.invalid.packageValue),

    packageCod: Yup.number()
      .noWhitespace(dictionary.invalid.packageCod)
      .nullable()
      .typeError(dictionary.invalid.packageCod)
      .min(0, dictionary.invalid.packageCod),

    packageExternalId: Yup.string()
      .nullable()
      .when('agentService', {
        is: '30',
        then: Yup.string().required(dictionary.required.externalId),
      }),

    packageVariableSymbol: Yup.number()
      .noWhitespace(dictionary.invalid.variableSymbol)
      .nullable()
      .typeError(dictionary.invalid.variableSymbol)
      .integer(dictionary.invalid.variableSymbol)
      .min(1, dictionary.invalid.variableSymbol)
      .when('packageCod', {
        is: (val) => val > 0,
        then: Yup.number().required(dictionary.required.variableSymbol),
      }),

    packages: Yup.array().of(packageSchema),
    // .required()
    // .min(1),
  });

  let schema = coreSchema;

  if (senderPickupType === 'collectionPlace') {
    schema = schema.concat(senderCollectionPlaceSchema);

    if (requiresCollectionPlacePostCode) {
      schema = schema.concat(senderCollectionPlacePostCodeSchema);
    }
  }

  if (senderPickupType === 'address') {
    schema = schema.concat(senderAddressSchema);
  }

  if (recipientPickupType === 'collectionPlace') {
    schema = schema.concat(recipientCollectionPlaceSchema);
  }

  if (recipientPickupType === 'pickupPlace') {
    schema = schema.concat(recipientPickupPlaceSchema);
  }

  if (recipientPickupType === 'address') {
    schema = schema.concat(recipientAddressSchema);
  }

  return schema;
}

// export async function validatePackageField(fieldName, value) {
//     const schema = Yup.reach(packageItemSchema, fieldName);

//     try {
//         await schema.validate(value);

//         return undefined;
//     } catch (error) {
//         return error.message;
//     }
// }

export default formSchema;
