import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import airbrake from 'Services/Airbrake';
import Hotjar from 'Services/Hotjar';
import Intercom from 'Services/Intercom';
import UserReducer from 'Types/redux/Reducers/UserReducer';
import GoogleAnalytics from 'Services/GoogleAnalytics';
import UserDeliveryInteractionTracking from 'Services/UserDeliveryInteractionTracking';
import configurator from '~/services/Configurator';

export const ServiceLocatorContext = React.createContext();

class ServiceLocator extends React.Component {
  static initProductionServices() {
    airbrake.init();
    Hotjar.init();
    GoogleAnalytics.init();
    Intercom.init();
  }

  constructor(props) {
    super(props);
    this.userDeliveryInteractionTracking = new UserDeliveryInteractionTracking();
  }

  componentDidMount() {
    if (configurator.env !== 'production') return;
    ServiceLocator.initProductionServices();
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    const user = this.props.user?.data;
    const previousUser = prevProps?.user?.data;

    if (user && previousUser?.login !== user?.login) {
      Intercom.setUser(
        user.name, user.surname, user.email, user.phone, user.company.name, user.company.website,
        user.company.id, user.company.subscription?.subscriptionType?.systemName,
      );

      // TODO: company && not admin && check roles
      if (user?.company) {
        this.userDeliveryInteractionTracking.setUser(user?.id, user?.name, user?.surname, user?.company.id);
      }
    }
  }

  render() {
    const { children } = this.props;

    return (
      <ServiceLocatorContext.Provider value={{
        userDeliveryTracking: {
          trackUserDeliveryEdit: this.userDeliveryInteractionTracking
            ? this.userDeliveryInteractionTracking.trackUserDeliveryEdit
            : () => null,
          untrackUserDeliveryEdit: this.userDeliveryInteractionTracking
            ? this.userDeliveryInteractionTracking.untrackUserDeliveryEdit
            : () => null,
          addUserDeliveryEditListener: this.userDeliveryInteractionTracking
            ? this.userDeliveryInteractionTracking.addUserDeliveryEditListener
            : () => null,
        },
      }}
      >
        {children}
      </ServiceLocatorContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.userReducer };
}

ServiceLocator.propTypes = {
  children: PropTypes.element.isRequired,
  user: UserReducer.state().isRequired,
};

export default connect(mapStateToProps)(ServiceLocator);
