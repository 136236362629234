import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Banner from '~/components/Banner/Banner';
import ApiTokenBanner from '~/components/GlobalBanners/ApiTokenBanner/ApiTokenBanner';

type Preferences = {
  dismissedSetupModuleBanner: boolean;
  dismissedSetupBrandingBanner: boolean;
  dismissedActivateTTBanner: boolean;
  dismissedActivateMailingBanner: boolean;
};

const DEFAULT_PREFERENCES: Preferences = {
  dismissedSetupModuleBanner: false,
  dismissedSetupBrandingBanner: false,
  dismissedActivateTTBanner: false,
  dismissedActivateMailingBanner: false,
};

type BannerDefinition = {
  title?: string;
  imgSrc?: string;
  imgAlt?: string;
  buttonText?: string;
  content?: string | JSX.Element;
  onDismiss?: () => void;
  route?: string;
  isActive: boolean;
  component?: JSX.Element;
};

function getPreferences(userId: string): Preferences {
  try {
    const preferences = localStorage.getItem(`${userId}-preferences`);

    if (!preferences) {
      return DEFAULT_PREFERENCES;
    }

    return JSON.parse(preferences);
  } catch (e) {
    return DEFAULT_PREFERENCES;
  }
}

type Props = {
  userId: string;
  eshopPlatform?: Nullable<string>;
  hasCollectionPlace?: boolean;
  hasSetCredentials?: boolean;
  hasDeliveriesFromApi?: boolean;
  hasActiveTrackAndTrace?: boolean;
  hasActiveMailing?: boolean;
  hasBrandingFeature?: boolean;
};

const GlobalBanners: React.FC<Props> = ({
  userId,
  hasSetCredentials,
  hasDeliveriesFromApi,
  hasCollectionPlace,
  eshopPlatform,
  hasActiveTrackAndTrace,
  hasActiveMailing,
  hasBrandingFeature,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [preferences, setPreferences] = React.useState<Preferences>(getPreferences(userId));

  const updatePreferences = (user: string, newPreferences: Partial<Preferences>) => {
    const updatedPreferences = {
      ...preferences,
      ...newPreferences,
    };

    try {
      localStorage.setItem(`${user}-preferences`, JSON.stringify(updatedPreferences));
    } catch (e) {
      // Swallow error
    } finally {
      setPreferences(updatedPreferences);
    }
  };

  const bannerDefinitions = useMemo<BannerDefinition[]>(
    () => ([
      // Create collection place banner
      {
        title: t('common:Banner.CreateCollectionPlace.title'),
        imgSrc: '/assets/img/banners/home.png',
        imgAlt: t('common:Banner.CreateCollectionPlace.imageDescription'),
        buttonText: t('common:Banner.CreateCollectionPlace.button'),
        content: (
          <>
            {t('common:Banner.CreateCollectionPlace.description')}
            {' '}
            {t('common:Banner.supportInfo')}
            {' '}
            <a href="mailto:info@foxdeli.com" className="intercom-toggle">
              {t('common:Banner.supportLink')}
            </a>
          </>
        ),
        route: '/settings/collection-places/new',
        isActive: !hasCollectionPlace,
      },

      // Setup carrier banner
      {
        title: t('common:Banner.SetupAgent.title'),
        imgSrc: '/assets/img/banners/dimensions.png',
        imgAlt: t('common:Banner.SetupAgent.imageDescription'),
        buttonText: t('common:Banner.SetupAgent.button'),
        content: (
          <>
            {t('common:Banner.SetupAgent.description')}
            {' '}
            {t('common:Banner.supportInfo')}
            {' '}
            <a href="mailto:info@foxdeli.com" className="intercom-toggle">
              {t('common:Banner.supportLink')}
            </a>
          </>
        ),
        route: '/settings/agents',
        isActive: !hasSetCredentials,
      },

      // Setup module banner
      {
        component: <ApiTokenBanner onDismiss={() => updatePreferences(userId, { dismissedSetupModuleBanner: true })} />,
        isActive: !preferences?.dismissedSetupModuleBanner && eshopPlatform === 'shoptet' && !hasDeliveriesFromApi,
      },

      // Setup branding banner
      {
        title: t('common:Banner.SetupBranding.title'),
        imgSrc: '/assets/img/banners/communication.png',
        imgAlt: t('common:Banner.SetupBranding.imageDescription'),
        buttonText: t('common:Banner.SetupBranding.button'),
        content: t('common:Banner.SetupBranding.description'),
        onDismiss: () => updatePreferences(userId, { dismissedSetupBrandingBanner: true }),
        route: '/settings/branding/track-and-trace',
        isActive: !preferences?.dismissedSetupBrandingBanner && !!hasBrandingFeature && !hasActiveTrackAndTrace && !hasActiveMailing,
      },

      // Activate Track&Trace banner
      {
        title: t('common:Banner.ActivateTrackAndTrace.title'),
        imgSrc: '/assets/img/banners/note-x.png',
        imgAlt: t('common:Banner.ActivateTrackAndTrace.imageDescription'),
        buttonText: t('common:Banner.ActivateTrackAndTrace.button'),
        content: t('common:Banner.ActivateTrackAndTrace.description'),
        onDismiss: () => updatePreferences(userId, { dismissedActivateTTBanner: true }),
        route: '/settings/branding/track-and-trace',
        isActive: !preferences?.dismissedActivateTTBanner && !!hasBrandingFeature && !!hasActiveMailing && !hasActiveTrackAndTrace,
      },

      // Activate mailing banner
      {
        title: t('common:Banner.ActivateMailing.title'),
        imgSrc: '/assets/img/banners/message.png',
        imgAlt: t('common:Banner.ActivateMailing.imageDescription'),
        buttonText: t('common:Banner.ActivateMailing.button'),
        content: t('common:Banner.ActivateMailing.description'),
        onDismiss: () => updatePreferences(userId, { dismissedActivateMailingBanner: true }),
        route: '/settings/branding/mailing',
        isActive: !preferences?.dismissedActivateMailingBanner && !!hasBrandingFeature && !!hasActiveTrackAndTrace && !hasActiveMailing,
      },
    ]),
    [
      t,
      hasSetCredentials,
      hasDeliveriesFromApi,
      hasCollectionPlace,
      eshopPlatform,
      hasActiveTrackAndTrace,
      hasActiveMailing,
      hasBrandingFeature,
      preferences,
      userId,
    ],
  );

  const activeBanner = bannerDefinitions.find((b) => b.isActive) ?? null;

  if (!activeBanner || (activeBanner?.route && location.pathname.includes(activeBanner.route))) {
    return null;
  }

  if (activeBanner?.component) {
    return activeBanner.component;
  }

  return (
    <Banner
      title={activeBanner.title}
      imgSrc={activeBanner.imgSrc}
      imgAlt={activeBanner.imgAlt}
      buttonText={activeBanner.buttonText}
      buttonLink={activeBanner.route}
      canDismiss={activeBanner.onDismiss !== undefined}
      onDismiss={activeBanner.onDismiss}
    >
      {activeBanner.content}
    </Banner>
  );
};

export default GlobalBanners;
