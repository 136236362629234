import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';

import dexieDriver from 'Services/DexieDriver';
import AuthenticationHelper from '~/services/Auth';
import configurator from '~/services/Configurator';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    AuthenticationHelper.clearCookies();
  }

  async componentDidMount() {
    try {
      await dexieDriver.clearDb();

      if (configurator.config?.firebase?.apiKey) {
        await firebase.auth().signOut();
      }
    } catch (e) {
      console.error('Firebase sign out failed.');
      console.error(e);
    }

    window.location.replace(`${configurator.config.iamUrl}/login`);
  }

  render() {
    return null;
  }
}

export default Logout;
